import React, {Fragment} from 'react';

import icondiscord from '../assets/social media/icon_discord.png';
import icontwitter from '../assets/social media/icon_twitter.png';
import iconitchio from '../assets/social media/icon_itchio.png';

function Footer() {
    return (
        <div className="footer">
            <div style={{width: 500, display: "flex", justifyContent: "space-between"}}>
                <p className="footer-item" style={{marginBottom:5}}>A game by <a href="https://www.briakatrina.com/" rel="noopener noreferrer" target="_blank">Briakitten</a></p>
                <div>
                    <a className="footer-item" href="https://discord.gg/4NnjssazCn" rel="noopener noreferrer" target="_blank"><img src={icondiscord} alt="icon discord" className="pixel"></img></a>
                    <a className="footer-item" href="https://twitter.com/heartrift" rel="noopener noreferrer" target="_blank"><img src={icontwitter} alt="icon discord" className="pixel"></img></a>
                    <a className="footer-item" href="https://briakitten.itch.io/heartrift" rel="noopener noreferrer" target="_blank"><img src={iconitchio} alt="icon discord" className="pixel"></img></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;