import React from 'react';
import {NavLink, useNavigate, useLocation } from 'react-router-dom';

import banner from '../assets/banner.png';
import banner_item from '../assets/banner-item.gif';
import tab from '../assets/tab.png';
import tab_about from '../assets/tab_about.png';
import tab_updates from '../assets/tab_updates.png';
import tab_demo from '../assets/tab_demo.png';
import tabselected from '../assets/tabselected.png';
import tabselected_about from '../assets/tabselected_about.png';
import tabselected_updates from '../assets/tabselected_updates.png';
import tabselected_demo from '../assets/tabselected_demo.png';

function Navbar() {

    let navigate = useNavigate();

    function goHome() {
        navigate("/");
    }

    const { pathname }  = useLocation();
    const pathRoute = pathname.split("/")[1];

    return (
        <div className="navbar">
            <ul className="banner">
                <NavLink to="/cat" className="banner-item"><img src={banner_item} alt="banner-item" className="pixel banner-item" /></NavLink>
                <NavLink to="/" className="banner-item"><img src={banner} alt="banner-logo" className="pixel banner-logo" /></NavLink>
            </ul>
            <ul className="navbar-tabs">
                <NavLink to="/about" className="navbar-item">
                    <img className="pixel tab-about" style={{visibility:"visible"}} src={pathRoute==="about"?tabselected_about:tab_about} alt=""></img>
                </NavLink>
                <NavLink to="/updates" className="navbar-item">
                    <img className="pixel tab" style={{visibility:"visible"}} src={pathRoute==="updates"?tabselected_updates:tab_updates} alt=""></img>
                </NavLink>
                <NavLink to="/demo" className="navbar-item">
                    <img className="pixel tab-demo" style={{visibility:"visible"}} src={pathRoute==="demo"?tabselected_demo:tab_demo} alt=""></img>
                </NavLink>
            </ul>
        </div>
    )
}

export default Navbar;