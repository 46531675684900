import React, {useState, Fragment} from 'react';
import Navbar from './components/Navbar';
import  { Routes, Route, useLocation} from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Updates from './pages/Updates';
import Demo from './pages/Demo';
import Cat from './pages/Cat';
import JSX_withParallax from './components/ParallaxJSXWrapper';
import TransitionStatic from './components/TransitionStatic';

import background_about from './assets/background_about.png';
import background_about_stars1 from './assets/background_about_stars1.png';
import background_about_stars2 from './assets/background_about_stars2.png';
import background_updates from './assets/background_updates.png';
import foreground_static from './assets/pixelstatic.gif';
import gradientbottom from './assets/gradienthorizontal.png';

import './index.css';
import Footer from './components/Footer';
import './assets/fonts/mainnewsmol.ttf';

function App() {
    // const [transition, setTransition] = useState(false);

    const location = useLocation();
    const { pathname }  = location;
    const pathRoute = pathname.split("/")[1];

    const ParallaxAbout1 = JSX_withParallax(<div style={styles_about.obj}></div>, 0.2, true);
    const ParallaxAbout2 = JSX_withParallax(<div style={styles_about.obj2}></div>, 0.1, true);

    const ParallaxUpdates1 = JSX_withParallax(<div style={styles_updates.obj}></div>, 0.4, false);

    const ForegroundStatic = TransitionStatic(-1);

    React.useEffect(() => {
        // re-renders on location change
        // setTransition(true);

        // setTimeout(() => {setTransition(false);}, 800);
    }, [location])

    return (
        <div className="outer" >
            <div className="parallax" style={
                (pathRoute===""||pathRoute==="cat")?{backgroundRepeat:"repeat", backgroundImage: `url(${background_about})`}:
                pathRoute==="about"?{backgroundColor:"#0273d6", height:"400%"}:
                pathRoute==="updates"?{backgroundColor:"#000000"}:
                pathRoute==="demo"?{backgroundColor:"#200b49"}:{}
                }></div>
            {pathRoute==="about"&&<ParallaxAbout1 />}
            {pathRoute==="about"&&<ParallaxAbout2 />}
            {pathRoute==="demo"&&<div style={{backgroundRepeat:"repeat", backgroundImage:`url(${foreground_static})`,
                opacity:0.25, width:"100%", height:"100%", position: "fixed", transform: "scale(8)", imageRendering: "pixelated", zIndex:-1}}></div>}
            {pathRoute==="updates"&&<ParallaxUpdates1 />}
            {true&&<ForegroundStatic opacity={0.2}/>}
            <Navbar></Navbar>
            <div className="content">
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/cat" element={<Cat/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/updates" element={<Updates/>}/>
                    <Route path="/demo" element={<Demo/>}/>
                </Routes>
            </div>
            {pathRoute==="about"&&<h1 className="bigmargintext"
                style={{alignItems: "end", display: "block", textAlign: "center"}}>
                ...</h1>}
            {pathRoute==="about"&&<h1 className="bigmargintext"
                style={{alignItems: "end", display: "block", textAlign: "center"}}>
                What the...?</h1>}
            {pathRoute==="about"&&<h1 className="bigmargintext"
                style={{alignItems: "end", display: "block", textAlign: "center"}}>
                How did you get here?</h1>}
            {pathRoute==="about"&&<h1 className="bigmargintext"
                style={{alignItems: "end", display: "block", textAlign: "center"}}>
                I guess we're just... drifting through space then.</h1>}
            {pathRoute==="about"&&<h1 className="bigmargintext"
                style={{alignItems: "end", display: "block", textAlign: "center"}}>
                <br/></h1>}
            <Footer />
            <div style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                transform: "scale(1, 2)",
                height: 100,
                backgroundImage:`url(${gradientbottom})`,
                backgroundRepeat: "none",
                pointerEvents:"none",
                zIndex: -2
            }}></div>
        </div>
    );
}

const styles_about = {
	obj: {
		margin: 0,
		padding: 0,
        width: "100%",
        height: "2200%",
		position: 'fixed',
		top: '0%',
		left: '0%',
		transform: 'translate(-50%, -50%)',
        transform: 'scale(1.5, 1.5)',
		backgroundRepeat:"repeat",
        backgroundImage: `url(${background_about_stars1})`,
        zIndex: -3
	},
    obj2: {
		margin: 0,
		padding: 0,
        width: "100%",
        height: "2200%",
		position: 'fixed',
		top: '0%',
		left: '0%',
		transform: 'translate(-50%, -50%)',
        transform: 'scale(1.5, 1.5)',
		backgroundRepeat:"repeat",
        backgroundImage: `url(${background_about_stars2})`,
        zIndex: -3
	}
}

const styles_updates = {
	obj: {
		margin: 0,
		padding: 0,
        width: "100%",
        height: "2000%",
		position: 'fixed',
		top: 200,
		left: '50%',
		transform: 'translate(-50%, -50%)',
		backgroundRepeat:"repeat",
        backgroundImage: `url(${background_updates})`,
        backgroundSize: "100%",
        zIndex: -3
	}
}

export default App;