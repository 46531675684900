import React from 'react';
import Canvas from '../components/Canvas';
import CanvasMainbox from '../components/CanvasMainbox';

import mainbox from '../assets/mainboxslice.png';
import brandportrait from '../assets/Brand_Portrait_x2.png';
import Bunnies from '../components/Bunnies';

function Home() {
    const _style = {
        borderWidth: "32px",
        borderStyle: "solid",
        borderImage: `url(${mainbox})`,
        borderImageSlice: "34 fill",
        borderImageRepeat: "round",
        alignItems: "start",
        display: "block",
        textAlign: "center",
        backgroundSize: "contain",
        imageRendering: "pixelated"
    }

    return (
        <div className="mainbox" style={_style}>
            {/* <CanvasMainbox width="480" height="400"/> */}
            <p className="maintext">...Stay tuned...</p>
            <img src={brandportrait} alt="brand image"></img>
        </div>
    )
}

export default Home;