import React from 'react';

import mainbox from '../assets/mainboxslice.png';
import poofy from '../assets/DemoNowOut_noshadow.png';

function Demo() {
    const _style = {
        borderWidth: "32px",
        borderStyle: "solid",
        borderImage: `url(${mainbox})`,
        borderImageSlice: "34 fill",
        borderImageRepeat: "round",
        height: 400,
        alignItems: "start",
        display: "block",
        textAlign: "center",
        backgroundSize: "contain",
        imageRendering: "pixelated"
    }

    return (
        <div className="mainbox" style={_style}>
            <p className="maintext" style={{marginTop: 0, marginBottom: 0}}>You can play the game on itch.io... <a style={{alignContent:"center"}} href="https://briakitten.itch.io/heartrift" rel="noopener noreferrer" target="_blank">here!</a></p>
            <a style={{alignContent:"center"}} href="https://briakitten.itch.io/heartrift" rel="noopener noreferrer" target="_blank">
                <img src={poofy} alt="Poofy image going hUH??"></img>
            </a>

        </div>

    )
}

export default Demo;