import Phaser from 'phaser';

import playerSprite from '../assets/cat/CatSpritesheet.png';

export default class ExampleScene extends Phaser.Scene {
    preload() {
    //   this.load.image("poofy", poofy)
    }
    create() {
        this.textures.addBase64('playerSprite', playerSprite)
        this.textures.on('onload', () => {this.createGameObjects()})
        // this.createGameObjects.bind(this)

        this.game.events.emit("READY", true)
    }

    createGameObjects() {
        this.add.image(100, 100, 'playerSprite')


        const text = this.add.text(12, 12, "Under construction...")
        text.setInteractive({ useHandCursor: true })
    }
  }