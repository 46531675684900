import React from 'react';
import CanvasMainbox from '../components/CanvasMainbox';
import PhaserMainbox from '../components/PhaserMainbox';
import Bunnies from '../components/Bunnies';

import mainbox from '../assets/mainboxslice.png';

function Cat() {
    const _style = {
        borderWidth: "32px",
        borderStyle: "solid",
        borderImage: `url(${mainbox})`,
        borderImageSlice: "34 fill",
        borderImageRepeat: "round",
        height: 400,
        alignItems: "start",
        display: "block",
        textAlign: "center"
    }

    return (
        <div className="mainbox" style={_style}>
            {/* <CanvasMainbox className="canvasbox" width="480" height="400" /> */}
            {/* <PhaserMainbox className="canvasbox" width="480" height="400" /> */}
            <Bunnies />
        </div>

    )
}

export default Cat;