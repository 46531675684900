import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown'

import mainbox from '../assets/mainboxslice.png';

import Post1 from '../markdown/a.md';
import Post2 from '../markdown/2022-5-27.md';

function Updates() {
    const [post1, setPost1] = useState('')
    const [post2, setPost2] = useState('')
    const [height, setHeight] = useState(0);

    const _style = {
        borderWidth: "32px",
        borderStyle: "solid",
        borderImage: `url(${mainbox})`,
        borderImageSlice: "34 fill",
        borderImageRepeat: "round",
        height: height + 100,
        alignItems: "start",
        display: "block",
        textAlign: "center",
        backgroundSize: "contain",
        imageRendering: "pixelated"
    }

    const heightHandler = () => {
        setHeight(document.getElementById('blog-posts').clientHeight);
    }

    useEffect(() => {
		fetch(Post1).then(res => res.text()).then(text => setPost1(text));
        fetch(Post2).then(res => res.text()).then(text => setPost2(text));
        window.onresize = heightHandler;
        window.onscroll = heightHandler;
        setHeight(document.getElementById('blog-posts').clientHeight);
        setTimeout(heightHandler, 30);
        setTimeout(heightHandler, 60);
        setTimeout(heightHandler, 120);
        setTimeout(heightHandler, 240);
	})

    return (
        <div className="mainbox" style={_style}>
            <p className="maintext">News, updates, etc...</p>
            <div id="blog-posts">
                <ReactMarkdown className="maintext" children={post2} />
                <ReactMarkdown className="maintext" children={post1} />
            </div>
        </div>
    )
}

export default Updates;