import { Container, Sprite, Stage, useTick } from "@inlet/react-pixi";
import * as PIXI from 'pixi.js'
import React, { useEffect, useState } from 'react';

import poofy from '../assets/PoofyHuh.png';

PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST

let index = 0;
const useDrag = ({ x, y }) => {
  const sprite = React.useRef();
  const [isDragging, setIsDragging] = React.useState(false);
  const [position, setPosition] = React.useState({ x, y });
  const [zIndex, setZIndex] = React.useState(0);

  const onDown = React.useCallback(() => {
    setIsDragging(true);
    setZIndex(index++);
  }, []);
  const onUp = React.useCallback(() => setIsDragging(false), []);
  const onMove = React.useCallback(e => {
    if (isDragging && sprite.current) {
      setPosition(e.data.getLocalPosition(sprite.current.parent));
    }

  }, [isDragging, setPosition]);

  return {
    ref: sprite,
    interactive: true,
    pointerdown: onDown,
    pointerup: onUp,
    pointerupoutside: onUp,
    pointermove: onMove,
    alpha: isDragging ? 0.5 : 1,
    zIndex,
    anchor: 0.5,
    position,
  };
};

let i = 0;

const DraggableBunny = ({ x = 400, y = 200, ...props }) => {
  const bind = useDrag({ x, y });
  const [scale, setScale] = React.useState(1);
  const [time, setTime] = React.useState(Math.random()*1000);

  // custom ticker
  useTick((delta) => {
    setTime(time + delta*0.05);
    delta && setScale(scale + Math.sin(time)*0.005)
  });

  return (
    <Sprite
      image={poofy}
      scale={scale}
      {...bind}
      {...props}
    />
  );
}

const Bunnies = () => {
  const xoffset = 100;

  const [width, setWidth] = useState(512);

  const resize = function() {
    const newWidth = window.innerWidth < 500 ? window.innerWidth - 112 : 500
    setWidth(newWidth);
  }

  useEffect(() => {
    resize();
  }, []);

  window.addEventListener('resize', resize);

  return (
    <Stage width={width} height={400} options={{backgroundAlpha: 0}}>
      <Container sortableChildren={true}>
        <DraggableBunny x={xoffset+25}  />
        <DraggableBunny x={xoffset+50} />
        <DraggableBunny x={xoffset+100} />
        <DraggableBunny x={xoffset+150} />
      </Container>
    </Stage>
  )
};

export default Bunnies;