import React, { useState, useEffect, useRef, Fragment} from 'react';

import gradient from '../assets/gradient.png';

import mainbox from '../assets/mainboxslice.png';
import tvrunning from '../assets/screencaptures/tvrunning.gif';
import staticweather from '../assets/screencaptures/staticweather.gif';
import kittenhouse from '../assets/screencaptures/kittenhouse.gif';
import screenshotinventory from '../assets/screencaptures/inventory.png';
import screenshotcards from '../assets/screencaptures/cards.png';
import meow from '../assets/screencaptures/meow.gif';
import poofy from '../assets/screencaptures/poofyscreenshot.PNG';
import newitem from '../assets/screencaptures/newitem.png';
import screenshotstatic from '../assets/screencaptures/lookingintostatic.png';

function About() {
    const [height, setHeight] = useState(0);

    const _style = {
        borderWidth: "32px",
        borderStyle: "solid",
        borderImage: `url(${mainbox})`,
        borderImageSlice: "34 fill",
        borderImageRepeat: "round",
        height: height,
        alignItems: "start",
        display: "block",
        textAlign: "center",
        backgroundSize: "contain",
        imageRendering: "pixelated"
    }

    const heightHandler = () => {
        setHeight(document.getElementById('updates-page').clientHeight);
    }

    useEffect(() => {
        window.onresize = heightHandler;
        window.onscroll = heightHandler;
        setHeight(document?.getElementById('updates-page').clientHeight);
        setTimeout(heightHandler, 30);
        setTimeout(heightHandler, 60);
	}, [])

    return (
        <Fragment>
        <div style={{
            position: "fixed",
            width: "200%", height: "200%",
            backgroundImage:`url(${gradient})`,
            backgroundRepeat:"repeat",
            backgroundPosition: "center",
            transform: "scale(7)",
            opacity: 1,
            pointerEvents:"none",
            zIndex: -4
        }}></div>
        <div style={{
            position: "fixed",
            width: "200%", height: "200%",
            backgroundImage:`url(${gradient})`,
            backgroundRepeat:"repeat",
            backgroundPosition: "center",
            transform: "scale(7)",
            imageRendering: "pixelated",
            opacity: 0.5,
            pointerEvents:"none",
            zIndex: -2
            }}></div>
        <div className="mainbox" style={_style}>
            <div id="updates-page">
                <p className="maintext">A surreal adventure game where you play as a cat.</p>
                <img src={tvrunning} alt="gif tv running" style={{marginBottom: 32}}></img>
                <p className="maintext">Explore (and make friends...?)</p>
                <img src={kittenhouse} alt="png tootsie house" style={{marginBottom: 32}}></img>
                <p className="maintext">Collect unique abilities, with cards.</p>
                <img src={screenshotinventory} alt="png inventory"></img>
                <img src={screenshotcards} alt="png cards" style={{marginBottom: 32}}></img>
                <p className="maintext">meow.</p>
                <img src={meow} alt="gif meow" style={{marginBottom: 32}}></img>
                <p className="maintext">Poofy! (the plushie)</p>
                <img src={newitem} alt="png poofy" style={{marginBottom: 32}}></img>
                <p className="maintext">Travel through ANCIENT technology (...or uh, really old TVs)</p>
                <img src={staticweather} alt="gif looking out the window" style={{marginBottom: 32}}></img>
                <p className="maintext">And more...</p>
                <img src={screenshotstatic} alt="png looking into static" style={{marginBottom: 32}}></img>
            </div>
        </div>
        </Fragment>
    )
}

export default About;