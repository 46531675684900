import React, {useState, useEffect, useRef} from 'react';
import useCanvas from '../helpers/hooks/useCanvas';
import resizeCanvas from '../helpers/resizeCanvas';

import mainbox from '../assets/mainbox.png';
import poofy from '../assets/PoofyHuh.png';
import playerSprite from '../assets/cat/CatSpritesheet.png';

function CanvasMainbox(props) {
    const imageObj1 = new Image();
    // const player = new Player();
    const imagePlayer = new Image();

    const pointerDown = (e) => {
    }

    /** @param {CanvasRenderingContext2D} context*/
    const initPlayer = (context) => {
        imagePlayer.src = playerSprite;
        imagePlayer.onload = function() {
            context.drawImage(imagePlayer, 0, 0);
        }

        context.canvas.addEventListener('touchdown', pointerDown);
        context.canvas.addEventListener('mousedown', pointerDown);
    }
    /** @param {CanvasRenderingContext2D} ctx*/
    const drawPlayer = (ctx, frameCount) => {
        // setPlayer({...player, targetX: 200, targetY: 200});

        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(
            imagePlayer, 0, 0, 16, 16,
                -32, 64 + 64*Math.sin(frameCount*0.05)**2,
                imagePlayer.width * 2, imagePlayer.height * 2
        );
    }

    const _init = (context, canvas) => {
        imageObj1.src = poofy;
        imageObj1.onload = function() {
            context.drawImage(imageObj1,0,0);
        }

        initPlayer(context);

        // player.init(context, 100, 100);
    }

    /** @param {CanvasRenderingContext2D} context*/
    const _predraw = (context, canvas) => {
        context.save();
        resizeCanvas(context, canvas);
        const {width, height} = context.canvas;
        context.clearRect(0, 0, width, height);
    }

    /** @param {CanvasRenderingContext2D} ctx*/
    const _draw = (ctx, frameCount) => {

        //ctx.scale(2, 2);

        ctx.fillStyle = "#290e5e";
        // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height); // draw rect with slight offset under mainbox.png
        ctx.fillStyle = "#0273d6";
        ctx.fillRect(0, 0, 4, ctx.canvas.height); // draw border
        ctx.fillRect(ctx.canvas.width - 4, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.fillRect(0, 0, ctx.canvas.width, 4);
        ctx.fillRect(0, ctx.canvas.height - 4, ctx.canvas.width, ctx.canvas.height);
        ctx.fillStyle = "#00aaff";
        ctx.beginPath();
        drawPlayer(ctx, frameCount);
        ctx.fill();
    }

    const {draw=_draw, predraw=_predraw, init=_init, options, ...rest} = props;
    const canvasRef = useCanvas(draw, {predraw, init});

    return <canvas ref={canvasRef} {...rest}/>;
}

export default CanvasMainbox;