import React, { Fragment } from 'react';

import foreground_static from '../assets/pixelstatic.gif';

/*function TransitionStatic(props) {

    const startTransition = () => {

    }

    return (
        <div style={styles_static}>

        </div>
    );
}*/

function TransitionStatic(zDepth) {
    return class extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                opacity: 0.25,
                visible: true,
                transition: true
            }
        }

        componentDidMount() {
            this.tvstatic_loop();

            setTimeout(() => {this.setState({transition: false});}, 800);
        }

        tvstatic_loop = () => {
            const visible = !(this.state.opacity % 2);
            if (visible) {
                this.state.opacity = this.props.opacity;
            } else {
                this.state.opacity = 0;
            }

            this.forceUpdate();

            const rand = Math.random();
            setTimeout(() => {
                this.state.visible = !this.state.visible;
                this.tvstatic_loop();
            }, visible ? 100 + 300 * rand : 100 + 50 * rand );
        }

        render() {
            return (
                <Fragment>
                    {this.state.transition&&<div style={{
                        position: "fixed",
                        width: "100%", height: "100%",
                        backgroundImage:`url(${foreground_static})`,
                        backgroundRepeat:"repeat",
                        transform: "scale(8)",
                        imageRendering: "pixelated",
                        opacity: this.state.opacity,
                        pointerEvents:"none",
                        zIndex: zDepth
                    }}></div>}
                </Fragment>
            )
        }
    }
}

export default TransitionStatic;